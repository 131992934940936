import { get, post } from '@/service/request';
import { dateFormat } from '@/filter';

const URL_PATH = `${BIBIZ_BASE_REQUEST_PATH}/energySystem`;
class EnergyIndexService {
    /**
     * 查询告警数量
     * @returns
     */
    async getElectricityCount() {
        const res = await get(`${URL_PATH}/electricity/count`);
        return res;
    }

    /**
     * 设备总数/异常设备
     * @returns
     */
    async getFacilityCount() {
        const url = `${URL_PATH}/facility/rts/count`;
        const res = await get(url);
        return res;
    }

    /**
     * 正在告警数量
     * @returns
     */
    async getAlarmActiveCount() {
        const url = `${URL_PATH}/alarm/rts/ACTIVE/count`;
        const res = await get(url);
        return res;
    }

    /**
     * 实时用电监控
     * @returns
     */
    async getElectricityRtsData(params?: any) {
        const url = `${URL_PATH}/electricity/rts/count`;
        const res = await post(url, params);
        res.ts = '合计';
        res.value = res.total;
        return {
            response: res,
            data: {
                columns: ['ts', '用电量'],
                rows: _.map(_.get(res, 'data'), item => {
                    return {
                        ts: dateFormat(item.ts, 'HH:mm'),
                        '用电量': item.value
                    };
                })
            },
            settings: {
                type: 'line',
                yAxisName: ['kWh'],
                area: true
            }
        };
    }

    /**
     * 分项用电统计
     * @returns
     */
    async getElectricityItemsData(params?: any) {
        const url = `${URL_PATH}/electricity/types/count`;
        const res = await post(url, params);
        return {
            columns: ['name', '能耗值'],
            rows: _.map(res, item => {
                return {
                    name: item.name,
                    '能耗值': item.value,
                    '占比': item.rate
                };
            })
        };
    }

    /**
     * 月度用电统计
     * @returns
     */
    async getElectricityMonthData(params?: any) {
        const url = `${URL_PATH}/electricity/month/count`;
        const res = await get(url, params);
        return {
            data: {
                columns: ['ts', '今年', '去年', '同比'],
                rows: _.map(res, item => {
                    return {
                        ts: dateFormat(item.ts, 'YYYY-MM'),
                        '今年': item.count,
                        '去年': item.lastYear,
                        '同比': item.rate
                    };
                })
            },
            settings: {
                type: 'line',
                yAxisType: ['normal', 'percent'],
                yAxisName: ['kWh', '同比'],
                axisSite: { right: ['同比'] }
            },
            extend: {
                series: {
                    type: 'line'
                },
                yAxis: [
                    {
                        splitLine: {
                            lineStyle: {
                                type: 'solid'
                            }
                        }
                    },
                    {
                        show: false,
                        splitLine: {
                            show: true,
                            lineStyle: {
                                type: 'dashed'
                            }
                        }
                    }
                ]
            }
        };
    }

    /**
     * 今天告警状态统计
     * @returns
     */
    async getAlarmStatusCount(params?: any) {
        const url = `${URL_PATH}/alarm/rts/STATUS/count`;
        const res = await get(url, params);
        return {
            columns: ['name', '数量'],
            rows: [
                { name: '已处理', '数量': _.get(res, 'solvedCount') || 0 },
                { name: '未处理', '数量': _.get(res, 'unsolvedCount') || 0 }
            ]
        };
    }
}

export default new EnergyIndexService();
