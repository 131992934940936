import moment, { Moment } from 'moment';
import { QueryControl, QueryControlType } from '@/model/query-control';
import { assignDefaultExtend } from '@/service/report';
import { QueryModel } from '@/model/query-model';
import EnergyIndexService from '../service/energy-index';
import { download } from '@/service/request';
import { changeTreeDataFieldName } from '../data-factory';
import { dateFormat } from '@/filter';
import { ChartLineExtend } from '@/mixins/report-chart-base';

export class EnergyElectricityRtsQueryModel extends QueryModel {
    @QueryControl({
        label: '日期',
        type: QueryControlType.SELECT_DATE,
        showTime: false,
        isRange: true,
        span: 8,
        required: true
    })
    dateRange: Array<Moment> = JTL.CONSTANT.TODAY();

    toService() {
        let endTime = this.dateRange[1].clone().add(1, 'day').startOf('day').toDate().getTime();
        const currentTime = new Date().getTime();
        if (endTime > currentTime) {
            endTime = currentTime;
        }
        return {
            startTime: this.dateRange[0].toDate().getTime(),
            endTime
        };
    }

    // @QueryControl({
    //     label: '日期',
    //     type: QueryControlType.SELECT_DATE,
    //     showTime: false,
    //     isRange: false,
    //     span: 8,
    //     required: true
    // })
    // date: Moment = moment();

    // toService() {
    //     return {
    //         startTime: this.date.clone().startOf('day').toDate().getTime(),
    //         endTime: this.date.clone().add(1, 'day').startOf('day').toDate().getTime()
    //     };
    // }
}

export async function EnergyElectricityRtsService(model: EnergyElectricityRtsQueryModel) {
    const res = await EnergyIndexService.getElectricityRtsData(model?.toService());
    const chartOptions = {
        title: '实时用电监控',
        data: res.data,
        settings: res.settings,
        extend: ChartLineExtend,
        legendVisible: false,
        tableRowKey: 'ts',
        tableExpandedRowKeys: [_.get(res, 'response.ts')],
        tableRows: changeTreeDataFieldName([res.response], 'data', 'children'),
        tableColumns: [
            {
                title: '时间',
                dataIndex: 'ts',
                customRender: (text, record, index) => {
                    return text === '合计' ? text : dateFormat(text, 'YYYY-MM-DD HH:mm');
                }
            },
            {
                title: '用电量',
                dataIndex: 'value'
            }
        ]
    };
    return assignDefaultExtend(chartOptions);
}

export async function EnergyElectricityRtsExportService(queryModel: EnergyElectricityRtsQueryModel) {
    const url = `${BIBIZ_BASE_REQUEST_PATH}/energySystem/electricity/rts/export/count`;
    const res = await download(url, queryModel?.toService(), 'post');
    return res;
}
